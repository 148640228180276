import React, { useState, useCallback } from 'react';
import axios from 'axios';
import { Typography, Button, CircularProgress, TextField, TextareaAutosize } from '@mui/material';

interface Config {
    is_quoting: boolean;
    extra_spread: number;
    quote_contracts: number;
}

interface MVEEventConfig {
    event_collection_ticker: string;
    config: Config;
    last_updated?: string;
}

const MVEConfigDashboard: React.FC = () => {
    const [configData, setConfigData] = useState<MVEEventConfig | null>(null);
    const [editedConfig, setEditedConfig] = useState<string>('');
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string | null>(null);
    const [ticker, setTicker] = useState<string>('KXGRAMMYWINNERS-67');
    const [isConfigValid, setIsConfigValid] = useState<boolean>(true);

    const baseUrl = process.env.REACT_APP_API_BASE_URL;

    const handleFetch = useCallback(async () => {
        if (!ticker) {
            setError('Please provide a ticker');
            return;
        }

        setLoading(true);
        setError(null);

        const token = localStorage.getItem('authToken');
        if (!token) {
            setError('You must be logged in.');
            setLoading(false);
            return;
        }

        try {
            const response = await axios.get(`${baseUrl}/mve-config/get?ticker=${encodeURIComponent(ticker)}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            setConfigData(response.data);
            setEditedConfig(JSON.stringify(response.data.config, null, 2));
            setIsConfigValid(true);
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to fetch data');
        } finally {
            setLoading(false);
        }
    }, [ticker, baseUrl]);

    const handleUpsert = async () => {
        if (!configData || !isConfigValid) {
            setError('Invalid or incomplete data.');
            return;
        }

        setLoading(true);
        setError(null);

        const token = localStorage.getItem('authToken');
        if (!token) {
            setError('You must be logged in.');
            setLoading(false);
            return;
        }

        try {
            const upsertData = {
                ...configData,
                config: JSON.parse(editedConfig),
                last_updated: new Date().toISOString(),
            };

            await axios.post(`${baseUrl}/mve-config/upsert`, [upsertData], {
                headers: { Authorization: `Bearer ${token}` },
            });

            setConfigData(upsertData);
            alert('Update successful!');
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to upsert data');
        } finally {
            setLoading(false);
        }
    };

    const handleDelete = async () => {
        if (!ticker) {
            setError('Please provide a ticker to delete');
            return;
        }

        setLoading(true);
        setError(null);

        const token = localStorage.getItem('authToken');
        if (!token) {
            setError('You must be logged in.');
            setLoading(false);
            return;
        }

        try {
            await axios.delete(`${baseUrl}/mve-config/delete?ticker=${encodeURIComponent(ticker)}`, {
                headers: { Authorization: `Bearer ${token}` },
            });
            alert('Delete successful!');
            setConfigData(null);
            setEditedConfig('');
        } catch (err) {
            setError(err instanceof Error ? err.message : 'Failed to delete data');
        } finally {
            setLoading(false);
        }
    };

    const validateConfig = (json: string) => {
        try {
            const parsed = JSON.parse(json);
            if (
                typeof parsed.is_quoting === 'boolean' &&
                typeof parsed.extra_spread === 'number' &&
                typeof parsed.quote_contracts === 'number'
            ) {
                setIsConfigValid(true);
                setError(null);
            } else {
                setIsConfigValid(false);
                setError('Invalid config format');
            }
        } catch {
            setIsConfigValid(false);
            setError('Invalid JSON structure');
        }
    };

    const handleConfigChange = (value: string) => {
        setEditedConfig(value);
        validateConfig(value);
    };

    const handlePreview = () => {
        if (!isConfigValid) {
            setError('Fix errors in the JSON before previewing.');
            return;
        }
        alert('Preview:\n' + editedConfig);
    };

    return (
        <div style={{ padding: '20px' }}>
            <Typography variant="h6" gutterBottom>
                Event Config Dashboard
            </Typography>

            <TextField
                label="Ticker"
                value={ticker}
                onChange={(e) => setTicker(e.target.value)}
                fullWidth
                margin="dense"
                placeholder="Enter event collection ticker"
            />

            <div style={{ display: 'flex', gap: '10px', marginTop: '10px' }}>
                <Button variant="contained" color="primary" onClick={handleFetch} disabled={loading}>
                    Fetch Config
                </Button>
                <Button variant="contained" color="error" onClick={handleDelete} disabled={loading}>
                    Delete Config
                </Button>
            </div>

            {loading && <CircularProgress style={{ marginTop: '20px' }} />}
            {error && <Typography color="error" style={{ marginTop: '10px' }}>{error}</Typography>}

            {configData && (
                <div style={{ marginTop: '20px' }}>
                    <Typography variant="subtitle1" gutterBottom>
                        Edit Config:
                    </Typography>
                    <TextareaAutosize
                        value={editedConfig}
                        onChange={(e) => handleConfigChange(e.target.value)}
                        style={{
                            width: '100%',
                            height: '200px',
                            fontFamily: 'monospace',
                            fontSize: '14px',
                            border: isConfigValid ? '1px solid green' : '1px solid red',
                            borderRadius: '4px',
                        }}
                    />
                    <Typography
                        variant="caption"
                        color={isConfigValid ? 'green' : 'error'}
                        style={{ display: 'block', marginTop: '5px' }}
                    >
                        {isConfigValid ? 'Valid JSON' : 'Invalid JSON'}
                    </Typography>
                    <div style={{ marginTop: '10px', display: 'flex', gap: '10px' }}>
                        <Button variant="contained" color="secondary" onClick={handlePreview}>
                            Preview Upsert
                        </Button>
                        <Button variant="contained" color="primary" onClick={handleUpsert} disabled={!isConfigValid}>
                            Confirm
                        </Button>
                    </div>
                </div>
            )}
        </div>
    );
};

export default MVEConfigDashboard;
