import React from 'react';
import { AppBar, Toolbar, Typography, Button, Box } from '@mui/material';

const Navbar: React.FC = () => {
    const handleNavigation = (path: string) => {
        window.location.href = path;
    };

    return (
        <AppBar position="static">
            <Toolbar>
                <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                    Dashboard
                </Typography>
                <Box>
                    <Button color="inherit" onClick={() => handleNavigation('/market-dash')}>
                        Market Dash
                    </Button>
                    <Button color="inherit" onClick={() => handleNavigation('/trades')}>
                        Trades
                    </Button>
                    <Button color="inherit" onClick={() => handleNavigation('/rules-list')}>
                        Rules List
                    </Button>
                    <Button color="inherit" onClick={() => handleNavigation('/market-and-order-info')}>
                        Market And Order Info
                    </Button>
                    <Button color="inherit" onClick={() => handleNavigation('/trader-action-dashboard')}>
                        Trader Action Dashboard
                    </Button>
                    <Button color="inherit" onClick={() => handleNavigation('/mve-configs')}>
                        MVE Configs
                    </Button>
                </Box>
            </Toolbar>
        </AppBar>
    );
};

export default Navbar;
